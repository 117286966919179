export const ROUTES = {
  HOME: '/',
  ABOUT_US: '/about-us',
  CAREERS: '/careers',
  FAQS: '/faqs',
  CONTACT_US: '/contact-us',
  REPORT_AN_ISSUE: '/report-an-issue',
  PARTNERS: 'https://partners.dailydumbbell.in',
  INVESTOR: '/investor/:page',
  INVESTOR_RELATIONS: '/investor/relations',
  REGISTER_GYM: '/partners/register',
  GYM_GUIDELINES: '/policy/guidelines',
  // BUSINESS_PRODUCTS: '/business-products',
  POLICIES: '/policy/:policyType',
  PRIVACY_POLICY: '/policy/privacy',
  REFUND_POLICY: '/policy/refund',
  COOKIE_POLICY: '/policy/cookie',
  CONTENT_POLICY: '/policy/content',
  TERMS_OF_SERVICE: '/policy/terms-of-service',
  SECURITY: '/policy/privacy#security',
  SITEMAP: '/sitemap',
  NOT_FOUND: '/error/404',
};
