import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ROUTES } from '../constants/routes';
import './index.scss';
const AboutUs = React.lazy(() => import('pages/about-us'));
const Policies = React.lazy(() => import('pages/policies'));
const Home = React.lazy(() => import('pages/home'));
const Careers = React.lazy(() => import('pages/careers'));
const ContactUs = React.lazy(() => import('pages/contact-us'));
const FAQS = React.lazy(() => import('pages/faqs'));
const ReportAnIssue = React.lazy(() => import('pages/report-an-issue'));
const Investor = React.lazy(() => import('pages/investor'));
const RegisterPartner = React.lazy(() => import('pages/register-partner'));
const NotFound = React.lazy(() => import('pages/not-found'));

const App = () => {
  return (
    <Router>
      <div id="app">
        <Suspense fallback={<div />}>
          <Routes>
            <Route exact path={ROUTES.HOME} element={<Home/>} />
            <Route exact path={ROUTES.ABOUT_US} element={<AboutUs/>} />
            <Route exact path={ROUTES.CAREERS} element={<Careers/>} />
            <Route exact path={ROUTES.FAQS} element={<FAQS/>} />
            <Route exact path={ROUTES.CONTACT_US} element={<ContactUs/>} />
            <Route
              exact
              path={ROUTES.REPORT_AN_ISSUE}
              element={<ReportAnIssue/>}
            />
            <Route path={ROUTES.INVESTOR} element={<Investor/>} />
            <Route path={ROUTES.POLICIES} element={<Policies/>} />
            <Route path={ROUTES.REGISTER_GYM} element={<RegisterPartner/>} />
            <Route path={'*'} element={<NotFound/>} />
          </Routes>
        </Suspense>
      </div>
    </Router>
  );
};

export default App;
